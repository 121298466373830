<template>
	<div style="margin: 30px 15px 15px 15px;">
		<div style="text-align: center;">
			<div class="title">关于叁個零 | ABOUT US</div>
		</div>
		
		<div style="padding: 20px 0;">
			我们倡导“叁零”健康白酒理念：<br/>
中国白酒（Chinese Baijiu）全产业链、全生命周期遵守“3个0”原则-“0兑0加0塑”，即“0食用酒精勾兑、0化学添加剂、0塑化剂”，倡导健康生活，保护你我健康。<br/>
我们设立“叁零”健康守护人制度，开展健康白酒理念的导入和宣传，引领中国白酒走向世界。<br/>
我们致力于为消费者筛选符合“3个0”标准的优质酒源、做好品控、致力于源头直采、提供高质、平价、健康、放心的酒品，免费提供个性化品牌定制服务。<br/>
我们致力于推广可应用于白酒行业（酿造、储运、包装、认证、调制、消费等全过程）的新材料、新工艺和新技术，赋能传统产业，倡导白酒产业的标准化、智能化、生态有机化和低碳环保化。<br/></div>
		<div style="text-align: center;">
			<div class="title">叁個零酒品 | PRODUCTS</div>
		</div>
		<div style="text-align: center;padding: 20px 0;"><img src="../../../public/static/product2.jpg" style="width: 100%;"/></div>
		<div style="padding-bottom: 20px;">我们提供以下系列酒品，均可提供个性化品牌定制，对于酒类经销商批发价另议。
	</div>
	<div class="title2">
		<div class="left">1</div>
		<div class="right">天款酱香白酒</div>
	</div>
	<div style="padding: 20px 0;">自然茅香、极品老酒，对标15年飞天，专用于高端接待/高档礼品/高净值人士口粮酒。<br/>
全国统一零售价￥1499元/500ml。</div>

	<div class="title2">
		<div class="left">2</div>
		<div class="right">人款酱香白酒</div>
	</div>
	<div style="padding: 20px 0;">大曲酱香、超高品质，用于口粮酒/重要商务接待。<br/>全国统一零售价￥799元/500ml。</div>

	</div>
</template>

<script>
import { init } from '@/common/weixinSDK'
export default {
	name: 'index',
	components: {
	},
	data() {
		return {
			title:"平台简介",
			imgUrl: 'https://static.tannatong.com/sanlinghui/common/mp_share.jpg'
		}
	},
	created() {
		init({
			APIs: ['hideOptionMenu', 
			'hideAllNonBaseMenuItem', 
			'showMenuItems', 
			'hideMenuItems', 
			'onMenuShareTimeline', 
			'onMenuShareAppMessage'],
			callback: {
				'title': '叁個零简介',
				'imgUrl': this.imgUrl,
				'description': '我们倡导"叁零”健康白酒理念:中国白酒（Chinese Baijiu）全产业链、全生命周期遵守“3个0”原则-“0兑0加0塑”',
				'url': "http://www.sangeling.com/about",
				successMethod: ''
			}
		})
	},
	methods: {
		
	}
}
</script>

<style>
.title{
	background-color: #fad182; 
	border-radius: 30px; 
	display: inline-block;
	padding: 5px 20px;
	font-weight: bold;
}
.title2{
	color: #fff;
	font-weight: bold;
	display: inline-block;
	background-color: #000000; 
	border-radius: 4px;
}
.title2 .left{
	display: inline-block;
	padding: 5px 10px; 
}
.title2 .right{
	display: inline-block; 
	background-color: #e6141d;
	padding: 5px 10px; 
	border-radius: 0 4px 4px 0;
}
</style>
